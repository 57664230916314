import React, { useRef, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  ChatBubbleBottomCenterTextIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { Input, Layout, AutoComplete, Flex } from 'antd';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';

import {
  getQuestions,
  getAnswer,
  getAnswerByUUID,
  getAllQuestions,
} from '../../services/ApiActions/scenarioAct';
import BreadcrumbContext from '../../components/Context';
import Toast from '../../services/ToastMessage';
import ChatMessages from './ChatMessages';
import './chat.styles.scss';
const { TextArea } = Input;
let optionSelected = false;
type ChatMessagesType = {
  messages: MessagesType[];
  animateId?: string;
};
export type MessagesType = {
  user_id?: string;
  message: string;
  question: string;
  summary: string;
  type: string;
  image?: string[];
  video?: string[];
  id: string;
  showDetail?: boolean;
  animatingId?: boolean;
};

const ChatPage = () => {
  const minCharacterLength = 3;
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const animatingIdRef = useRef<string | null>(null);
  const [chatMessages, setChatMessages] = useState<ChatMessagesType>({
    messages: [],
  });
  const [animatingId, setAnimatingId] = useState<string | null>(null);
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<
    { value: string; label: string; question: string }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    question?: string;
    uuid?: string;
    category?: string;
    answer?: string;
    summary?: string;
    type?: string;
    image?: string[];
    video?: string[];
  }>({
    value: '',
    question: undefined,
    uuid: undefined,
    category: undefined,
    answer: undefined,
    summary: undefined,
    type: undefined,
    image: undefined,
    video: undefined,
  });
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const location = useLocation();
  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    const { value, uuid, category } = selectedOption;
    if (
      value &&
      value.trim().length >= minCharacterLength &&
      !uuid &&
      !category
    ) {
      getQuestions({ question: value }, cancelSource.token)
        .then((res: any) => {
          if (res.status === 'success') {
            setOptions(
              !value
                ? []
                : res?.answer.map(
                    (
                      que: {
                        question: string;
                        uuid: string;
                        category: string;
                        answer: string;
                        summary: string;
                        image?: string[];
                        video?: string[];
                      },
                      i: number
                    ) => {
                      const val = `${i + 1}) ${que.question}`;
                      return {
                        value: val,
                        label: que.question,
                        question: que.question,
                        uuid: que.uuid,
                        category: que.category,
                        answer: que.answer,
                        summary: que.summary,
                        image: que.image || [],
                        video: que.video || [],
                      };
                    }
                  )
            );
          } else {
            setOptions([]);
          }
        })
        .catch((error) => {
          setOptions([]);
          Toast({
            type: 'error',
            message: error?.message || 'Something went wrong',
          });
        });
    } else if (value && (uuid || category)) {
      onMessageSend();
    } else {
      options && options.length && setOptions([]);
    }
    return () => {
      cancelSource.cancel('Component unmounted or dependency changed');
    };
  }, [selectedOption]);

  const onSelect = (
    value: string,
    option: {
      value: string;
      label: string;
      question: string;
      uuid?: string;
      category?: string;
      answer?: string;
      summary?: string;
      type?: string;
      image?: string[];
      video?: string[];
    }
  ) => {
    console.log('select called');
    optionSelected = true;
    setSelectedOption({
      value: option.label,
      question: option.label,
      uuid: option.uuid,
      category: option.category,
      answer: option.answer,
      summary: option.summary,
      type: option.type,
      image: option.image || [],
      video: option.video || [],
    });
    setOptions([]);
    //setPickedOption({value: option.value, uuid: option.uuid})
  };

  useEffect(() => {
    addBreadcrumbitem({
      breadCrumb: [
        { label: 'Spoon Feeding', url: '' },
        { label: 'Ask Me Anything', url: location.pathname },
      ],
      headingInfo: {
        title: 'Ask Me Anything',
        description: 'Your everyday AI Assistant',
      },
    });
    const localMessages = sessionStorage.getItem('chatMessages');
    if (localMessages) {
      const parsedMsg = JSON.parse(localMessages);
      setChatMessages({
        messages: parsedMsg.messages,
      });
    }
  }, []);

  useEffect(() => {
    if (chatMessages.messages.length > 0) {
      sessionStorage.setItem('chatMessages', JSON.stringify(chatMessages));
    }
  }, [chatMessages]);

  const onMessageSend = () => {
    const {
      value,
      uuid,
      category,
      answer,
      summary,
      question,
      type,
      image,
      video,
    } = selectedOption;
    if (value && value.trim() === '') {
      return;
    }
    setIsInitialLoad(false);
    setIsLoading(true);
    setSelectedOption({
      value: '',
      question: undefined,
      uuid: undefined,
      category: undefined,
      answer: undefined,
      summary: undefined,
      type: undefined,
      image: [],
      video: [],
    });
    const formattedQuery = value.replace(/\n/g, '\n\n');
    const id = uuidv4();
    const userString = sessionStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : undefined;
    setChatMessages((prev: any) => ({
      messages: [
        ...prev.messages,
        { user_id: user.id || '1', summary: formattedQuery, type: 'text', id },
      ],
    }));

    const fetchAnswer = (apiCall: Function) => {
      apiCall()
        .then((res: any) => {
          if (res.status === 'success') {
            const id = uuidv4();
            var newMessage = {
              user_id: '',
              message: res.answer,
              question: uuid ? question : value,
              summary: res.summary,
              image: res.image,
              video: res.video,
              type: res.type,
              id,
            };
            setChatMessages((prev: any) => ({
              messages: [...prev.messages, newMessage],
              animateId: id,
            }));
            setAnimatingId(id);
            animatingIdRef.current = id;
          }
          //setFetchAnswer(false);
          setIsLoading(false);
        })
        .catch((error: any) => {
          Toast({
            type: 'error',
            message: error?.message || 'Something went wrong',
          });
          //setFetchAnswer(false);
          setIsLoading(false);
        });
    };

    if (category) {
      setIsLoading(false);
      const id = uuidv4();
      if (!answer && image && image.length <= 0 && video && video.length <= 0) {
        return;
      }
      var newMessage = {
        user_id: '',
        message: answer,
        question: question,
        summary: summary,
        image: image || [],
        video: video || [],
        type: type || 'text',
        id,
      };
      setChatMessages((prev: any) => ({
        messages: [...prev.messages, newMessage],
        animateId: id,
      }));
      setAnimatingId(id);
      animatingIdRef.current = id;
      return;
    } else if (uuid) {
      fetchAnswer(() => getAnswerByUUID({ uuid: uuid }));
    } else {
      fetchAnswer(() => getAnswer({ question: value }));
    }
  };

  const handleMessageChange = (value: string) => {
    //const { value } = e.target;
    optionSelected = false;
    setSelectedOption({
      value: value,
      uuid: '',
      category: '',
      question: '',
      answer: '',
      summary: '',
    });
    //setQuery(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (optionSelected) {
      return;
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      console.log('enter called');
      event.preventDefault();
      /*if(options.length == 1 && options[0].value == selectedOption.value){
                return
            }*/
      onMessageSend();
    } else if (event.key === 'Enter' && event.shiftKey) {
      setSelectedOption((prevValue) => {
        return { ...prevValue, value: prevValue.value + '\n' };
      });
      //setQuery((prevValue) => prevValue + '\n');
      event.preventDefault();
    }
  };
  const handleOnNewChat = () => {
    sessionStorage.removeItem('chatMessages');
    setChatMessages({ messages: [] });
    setAnimatingId(null);
    animatingIdRef.current = null;
  };
  const handleToggleDetailedAnswer = (message: MessagesType) => {
    const updatedChatMessages = chatMessages.messages.map((chatMessage) =>
      chatMessage.id === message.id
        ? { ...chatMessage, showDetail: !chatMessage.showDetail }
        : chatMessage
    );
    setChatMessages({ messages: updatedChatMessages });
  };
  const handleToggleAutoSuggestInput = (
    message: MessagesType,
    status: boolean
  ) => {
    const animatingId = status ? message.id : null;
    if (!animatingIdRef.current) {
      return;
    }
    const updatedChatMessages = chatMessages.messages.map((chatMessage) =>
      chatMessage.id === message.id
        ? {
            ...chatMessage,
            isAnimating: status,
            animateId: animatingId, // Set animateId when status is true, otherwise null
          }
        : chatMessage
    );

    setChatMessages({ messages: updatedChatMessages });
    setAnimatingId(animatingId); // Update animateId state based on status
    animatingIdRef.current = animatingId;
  };
  const { value } = selectedOption;
  return (
    <div
      className='custom-container max-width'
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      <div style={{ textAlign: 'center', display: 'flex', color: '#1D322D80' }}>
        <div style={{ flexGrow: '1', marginBottom: '20px' }}>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}
          >
            <ChatBubbleBottomCenterTextIcon className='custom-icon hero-icon-24' />{' '}
            <div> Ask Me Anything</div>
          </div>
        </div>
        <div>
          <PencilSquareIcon
            className='custom-icon hero-icon-24 pointer'
            onClick={handleOnNewChat}
          />
        </div>
      </div>
      <ChatMessages
        isInitialLoad={isInitialLoad}
        chatMessages={chatMessages}
        animateId={animatingId}
        playingVideoId={playingVideoId}
        isLoading={isLoading}
        toggleDetailedAnswer={(message: any) =>
          handleToggleDetailedAnswer(message)
        }
        toggleAutoSuggestInput={(message: any, status: boolean) =>
          handleToggleAutoSuggestInput(message, status)
        }
        onVideoPlay={(videoId: string | null) => setPlayingVideoId(videoId)}
      />
      <Layout.Footer
        className={`message-footer`}
        style={{
          backgroundColor: 'transparent',
          paddingLeft: '0',
          paddingRight: '0',
          paddingBottom: '0',
        }}
      >
        {
          //style={{ position: 'relative' }}
        }
        <Flex
          gap={5}
          style={{ margin: '10px 0', alignItems: 'end' }}
          align='bottom'
        >
          <AutoComplete
            autoFocus
            options={options}
            style={{ flexGrow: 1, height: '100%' }}
            className='chat-auto-suggest'
            onSelect={onSelect}
            onKeyDown={(e) => {
              !(isLoading && animatingId) && handleKeyDown(e);
            }}
            onChange={(value) => {
              !(isLoading || animatingId) && handleMessageChange(value);
            }}
            //onSearch={handleSearch}
            value={value}
            //on

            disabled={animatingId || isLoading ? true : false}
          >
            <TextArea
              className='auto-suggest-input'
              placeholder='Ask Me Anything...'
              autoSize={{ minRows: 1, maxRows: 6 }}
              autoFocus
              //onKeyDown={handleKeyDown}
              //onChange={handleMessageChange}
              style={{
                flexGrow: 1,
                paddingRight: '40px',
                borderRadius: '15px',
              }}
            />
          </AutoComplete>
          <div
            style={{
              position: 'relative',
              right: '40px',
              width: '0',
              color: value && value.trim() !== '' ? '#001E5A' : '#808080',
            }}
          >
            <PaperAirplaneIcon
              onClick={() => {
                value && value.trim() !== '' && onMessageSend();
              }}
              className='custom-icon hero-icon-24'
            />
          </div>
        </Flex>
        <Flex gap='small' style={{ margin: '10px 0' }}>
          <div
            style={{
              flexGrow: 1,
              color: 'rgba(0, 0, 0, 0.45)',
              margin: '0',
              paddingLeft: '10px',
              //fontSize: '16px',
            }}
          >
            [Enter] send, [Shift+Enter] line break
          </div>
        </Flex>
      </Layout.Footer>
    </div>
  );
};
//const ChatListMemo = React.memo(ChatList);
export default ChatPage;
