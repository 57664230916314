import React, { useState } from 'react';
import {
  Typography,
  Modal,
  Layout,
  MenuProps,
  Menu,
  ConfigProvider,
  Space,
  Pagination,
  Row,
  Col,
} from 'antd';

import Loader from './Loader';
import RecordsList from '../Content/RecordList';
import { FilterBar } from '../Header/FilterBar';
import RecordItem from '../Content/RecordItem';
import ListItem from '../Content/ListItem';
import EmptyRecord from '../Content/EmptyRecord';
import DateTitle from '../Content/DateTitle';
import PageHeading from './PageHeading';
import PdfViewer from '../PDF/PDFViewer';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const { Sider, Content } = Layout;
const { Title } = Typography;

type MenuItem = Required<MenuProps>['items'][number];

interface ResearchLayoutProps {
  pagination?: React.ReactNode;
  tabs: any;
  title: string;
  records: any[];
  selectedMenu?: any;
  menuItems: any;
  isLoading?: boolean;
  defaultDateRange?: any;
  selectedTab: any;
  onRangeChange?: any;
  onPriorityChange?: any;
  priority?: any;
  onAllClick: any;
  onTabClick: any;
  isNewsletter?: boolean;
  showPriorityFilter?: boolean;
  tabItemsCount?: any;
  showDateRange?: boolean;
  dateFilterComponent?: React.ReactNode;
  selectedDateOption?: string;
}

const ResearchLayout: React.FC<ResearchLayoutProps> = ({
  pagination,
  tabs,
  title,
  records,
  selectedMenu,
  menuItems,
  isLoading,
  defaultDateRange,
  onRangeChange,
  onAllClick,
  selectedTab,
  onTabClick,
  isNewsletter,
  onPriorityChange,
  priority,
  showPriorityFilter,
  tabItemsCount,
  showDateRange,
  dateFilterComponent,
  selectedDateOption,
}) => {
  const [open, setOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [PDFURL, setPDFURL] = useState('');
  const [PDFTitle, setPDFTitle] = useState('');
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  const colSpan =
    records.length > 1 && selectedTab !== 'video' && menuItems.length <= 0
      ? 12
      : 24;
  const headerHeight = '106px';

  const onReadMoreClick = (articleHeading: string, pdfURL: string) => {
    setOpen(true);
    setIsVideo(false);
    setPDFURL(pdfURL);
    setPDFTitle(articleHeading);
  };
  const onPlayClick = (
    articleHeading: string,
    videoURL: string,
    id: string
  ) => {
    setVideoId(id);
    setOpen(true);
    setIsVideo(true);
    setPDFURL(videoURL);
    setPDFTitle(articleHeading);
  };
  const handleOnVideoPlay = (isPlaying: string | null) => {
    setPlayingVideoId(isPlaying);
  };
  return (
    <>
      <PageHeading heading={title} />
      <Layout>
        {menuItems.length > 0 && (
          <Sider
            width={220}
            style={{
              backgroundColor: 'transparent',
              maxHeight: `calc(100vh - ${headerHeight})`,
              overflow: 'auto',
            }}
          >
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    itemSelectedBg: '#001e5a',
                    itemSelectedColor: '#FFF',
                  },
                },
              }}
            >
              <Menu
                key={Math.random()}
                className='secondary-menu'
                defaultSelectedKeys={[selectedMenu]}
                style={{
                  backgroundColor: 'transparent',
                  height: '100%',
                  borderRight: 0,
                }}
                items={menuItems}
              />
            </ConfigProvider>
          </Sider>
        )}

        <Content
          style={{
            height: `calc(200px - ${headerHeight})`,
            marginLeft: menuItems.length > 0 ? '20px' : 0,
            //marginTop: menuItems.length > 0 ? '3px' : 0,
          }}
        >
          {
            <FilterBar
              tabs={selectedMenu === 'submenu-performance-report' ? [] : tabs}
              selectedTab={selectedTab}
              onTabClick={onTabClick}
              onAllClick={onAllClick}
              onRangeChange={onRangeChange}
              defaultDateRange={defaultDateRange}
              onPriorityChange={onPriorityChange}
              priority={priority}
              showPriorityFilter={showPriorityFilter}
              showDateRange={showDateRange}
              rangePresets={undefined}
              dateFormat={''}
              dateFilter={dateFilterComponent}
              selectedDateOption={selectedDateOption}
            />
          }

          {isLoading ? (
            <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />
          ) : (
            <>
              <div
                style={{
                  height: 'calc(100vh - 183px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  paddingRight: '10px',
                }}
              >
                {records.length > 0 ? (
                  <>
                    {records.map((item) => (
                      <>
                        {item.records.length > 0 && (
                          <>
                            <DateTitle date={item.date} />
                            <Row
                              gutter={[16, 16]}
                              style={{ marginBottom: '16px' }}
                            >
                              {item.records.map((rec: any) => {
                                return (
                                  <Col span={colSpan}>
                                    <ListItem
                                      isNewsletter={isNewsletter}
                                      key={item.id}
                                      item={{
                                        id: rec.id,
                                        title: rec.title,
                                        description: rec.description,
                                        date: rec.date,
                                        videoURL: rec.video_link,
                                        pdfURL: rec.pdf?.url,
                                      }}
                                      selectedTab={selectedTab}
                                      priority={priority}
                                      onReadMoreClick={onReadMoreClick}
                                      onPlayClick={onPlayClick}
                                      playingVideoId={playingVideoId}
                                      onVideoPlay={(isPlaying: string | null) =>
                                        handleOnVideoPlay(isPlaying)
                                      }
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          </>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <EmptyRecord />
                )}
              </div>
              {pagination}
            </>
          )}

          <Modal
            title={PDFTitle}
            footer={null}
            centered
            open={open}
            onCancel={() => {
              setOpen(false);
              setIsVideo(false);
              setPDFURL('');
            }}
            width={isVideo ? 800 : 1200}
            destroyOnClose
          >
            {isVideo ? (
              <VideoPlayer
                id={videoId}
                playingVideoId={playingVideoId}
                onVideoPlay={(id) => setPlayingVideoId(id)}
                videoURL={PDFURL}
              />
            ) : (
              <PdfViewer fileUrl={PDFURL} />
            )}
          </Modal>
        </Content>
      </Layout>
    </>
  );
};

export default ResearchLayout;
