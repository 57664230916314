import React, { useState } from 'react';
import {
  Typography,
  Modal,
  Layout,
  MenuProps,
  Menu,
  ConfigProvider,
  Space,
  Pagination,
  Row,
  Col,
} from 'antd';

import Loader from './Loader';
import RecordsList from '../Content/RecordList';
import { FilterBar } from '../Header/FilterBar';
import RecordItem from '../Content/RecordItem';
import ListItem from '../Content/ListItem';
import EmptyRecord from '../Content/EmptyRecord';
import PageHeading from './PageHeading';
import PdfViewer from '../PDF/PDFViewer';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const { Sider, Content } = Layout;
const { Title } = Typography;

type MenuItem = Required<MenuProps>['items'][number];

interface NewsletterLayoutProps {
  pagination?: React.ReactNode;
  tabs: any;
  title: string;
  records: any[];
  selectedMenu?: any;
  menuItems: any;
  isLoading?: boolean;
  defaultDateRange?: any;
  selectedTab: any;
  onRangeChange?: any;
  onPriorityChange?: any;
  priority?: any;
  onAllClick: any;
  onTabClick: any;
  isNewsletter?: boolean;
  includeDateInHeading?: boolean;
  showPriorityFilter?: boolean;
  tabItemsCount?: any;
  showDateRange?: boolean;
  dateFilterComponent?: React.ReactNode;
  selectedDateOption?: string;
}

const NewsletterLayout: React.FC<NewsletterLayoutProps> = ({
  pagination,
  tabs,
  title,
  records,
  selectedMenu,
  menuItems,
  isLoading,
  defaultDateRange,
  onRangeChange,
  onAllClick,
  selectedTab,
  onTabClick,
  isNewsletter,
  includeDateInHeading,
  onPriorityChange,
  priority,
  showPriorityFilter,
  tabItemsCount,
  showDateRange,
  dateFilterComponent,
  selectedDateOption,
}) => {
  console.log('records-');
  const [open, setOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  const [PDFURL, setPDFURL] = useState('');
  const [PDFTitle, setPDFTitle] = useState('');
  const colSpan =
    records.length > 1 && selectedTab !== 'video' && menuItems.length <= 0
      ? 12
      : 24;
  const headerHeight = '106px';

  const onReadMoreClick = (articleHeading: string, pdfURL: string) => {
    setOpen(true);
    setPDFURL(pdfURL);
    setPDFTitle(articleHeading);
  };
  const onPlayClick = (
    articleHeading: string,
    videoURL: string,
    id: string
  ) => {
    setVideoId(id);
    setOpen(true);
    setIsVideo(true);
    setPDFURL(videoURL);
    setPDFTitle(articleHeading);
  };
  const handleOnVideoPlay = (isPlaying: string | null) => {
    console.log(isPlaying);
    setPlayingVideoId(isPlaying);
  };
  return (
    <>
      <Layout>
        <PageHeading heading={title} />
        <Layout>
          {menuItems.length > 0 && (
            <Sider
              width={220}
              style={{
                backgroundColor: 'transparent',
                maxHeight: `calc(100vh - ${headerHeight})`,
                overflow: 'auto',
              }}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Menu: {
                      itemSelectedBg: '#001e5a',
                      itemSelectedColor: '#FFF',
                    },
                  },
                }}
              >
                <Menu
                  key={Math.random()}
                  className='secondary-menu'
                  defaultSelectedKeys={[selectedMenu]}
                  style={{
                    backgroundColor: 'transparent',
                    height: '100%',
                    borderRight: 0,
                  }}
                  items={menuItems}
                />
              </ConfigProvider>
            </Sider>
          )}

          <Content
            style={{
              height: `calc(100vh - ${headerHeight})`,
              marginLeft: menuItems.length > 0 ? '20px' : 0,
              //marginTop: menuItems.length > 0 ? '3px' : 0,
            }}
          >
            {
              <FilterBar
                tabs={selectedMenu === 'submenu-performance-report' ? [] : tabs}
                selectedTab={selectedTab}
                onTabClick={onTabClick}
                onAllClick={onAllClick}
                onRangeChange={onRangeChange}
                defaultDateRange={defaultDateRange}
                onPriorityChange={onPriorityChange}
                priority={priority}
                showPriorityFilter={showPriorityFilter}
                showDateRange={showDateRange}
                rangePresets={undefined}
                dateFormat={''}
                dateFilter={dateFilterComponent}
                selectedDateOption={selectedDateOption}
              />
            }

            {isLoading ? (
              <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />
            ) : (
              <>
                <div
                  style={{
                    height: 'calc(100vh - 182px)',
                    paddingRight: '10px',
                    overflowY: 'auto',
                  }}
                >
                  <div
                    className={
                      selectedTab === 'video' ? 'video-tab-content' : ''
                    }
                  >
                    {records.length > 0 ? (
                      <Row gutter={[16, 16]}>
                        {records.map((item) => (
                          <Col span={colSpan}>
                            <ListItem
                              playingVideoId={playingVideoId}
                              includeDateInHeading={includeDateInHeading}
                              isNewsletter={isNewsletter}
                              key={item.id}
                              item={item}
                              selectedTab={selectedTab}
                              priority={priority}
                              onReadMoreClick={onReadMoreClick}
                              onPlayClick={onPlayClick}
                              onVideoPlay={(isPlaying) =>
                                handleOnVideoPlay(isPlaying)
                              }
                            />
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <EmptyRecord />
                    )}
                  </div>

                  {/* <RecordsList
                tabs={tabs}
                priority={priority}
                isNewsletter={isNewsletter}
                records={records}
                selectedTab={selectedTab}
                onReadMoreClick={onReadMoreClick}
              /> */}
                </div>
                {pagination}
              </>
            )}

            <Modal
              title={PDFTitle}
              footer={null}
              centered
              open={open}
              onCancel={() => {
                setOpen(false);
                setIsVideo(false);
                setPDFURL('');
              }}
              width={isVideo ? 800 : 1200}
              destroyOnClose
            >
              {isVideo ? (
                <VideoPlayer
                  id={videoId}
                  playingVideoId={playingVideoId}
                  onVideoPlay={(id) => setPlayingVideoId(id)}
                  videoURL={PDFURL}
                />
              ) : (
                <PdfViewer fileUrl={PDFURL} />
              )}
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default NewsletterLayout;
