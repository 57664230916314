import React from 'react';
import { Card, Typography, message, Flex, Button } from 'antd';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { PlayIcon } from '@heroicons/react/24/outline';
const { Title, Text, Paragraph } = Typography;

interface ItemCardProps {
  id: string;
  title: string;
  description: string; // Changed to string for clarity
  date: string;
  pdfURL?: string;
  videoURL?: string;
  includeDateInHeading?: boolean;
  onReadMoreClick: (title: string, url: string) => void;
  onPlayClick?: (title: string, url: string, id: string) => void;
}

const ItemCard: React.FC<ItemCardProps> = ({
  title,
  description,
  date,
  pdfURL,
  videoURL,
  includeDateInHeading = false,
  id,
  onReadMoreClick,
  onPlayClick,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const printDate = () => {
    return new Date(date).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  const handleReadMoreClick = () => {
    if (!pdfURL) {
      messageApi.error('PDF is missing');
      return;
    }
    onReadMoreClick(title, pdfURL);
  };
  const handlePlayClick = () => {
    if (!videoURL) {
      messageApi.error('Video is missing');
      return;
    }
    onPlayClick && onPlayClick(title, videoURL, id);
  };

  return (
    <>
      {contextHolder}
      <Card className='item-card'>
        <Title
          ellipsis={{ rows: 1 }}
          className='heading article-heading'
          level={4}
          style={{
            margin: 0,
            marginBottom: '5px',
            //display: '-webkit-box',
            //WebkitLineClamp: 2,
            //WebkitBoxOrient: 'vertical',
            //overflow: 'hidden',
            //minHeight: '3em',
          }}
        >
          {title} {includeDateInHeading && `| ${printDate()}`}
        </Title>
        <Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            marginTop: 0,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            minHeight: '3em',
            fontSize: '14px',
            color: '#737272',
          }}
        >
          {description}
        </Paragraph>
        <Flex justify='space-between' gap={10} align='center'>
          {!includeDateInHeading ? (
            <Flex gap={5} align='center'>
              <CalendarDaysIcon width={16} style={{ color: '#737272' }} />
              <Text style={{ color: '#737272', fontSize: '11px' }}>
                {printDate()}
              </Text>
            </Flex>
          ) : (
            <div></div>
          )}
          <Flex gap={5} align='center'>
            <div>
              <Text
                style={{ color: '#001E5A', fontWeight: 500 }}
                className='pointer read-more'
                onClick={handleReadMoreClick}
              >
                Read More
              </Text>
              <ArrowUpRightIcon width={16} style={{ color: '#001E5A' }} />
            </div>
            <div style={{ color: '#737272' }}>|</div>
            <div>
              <Button
                //size="large"
                style={{ padding: 0 }}
                className={`tab-button curved-border`}
                onClick={handlePlayClick}
                icon={<PlayIcon width={17} />}
              >
                Video
              </Button>
            </div>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default ItemCard;
