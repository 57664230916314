import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  Layout,
  theme,
  Menu,
  Avatar,
  Flex,
  Modal,
  Button,
  Form,
  Dropdown,
  Input,
  Typography,
  Badge,
} from 'antd';
import type { MenuProps } from 'antd';
import dayjs from 'dayjs';
import {
  DocumentChartBarIcon,
  PresentationChartLineIcon,
  LightBulbIcon,
  Bars3Icon,
  Bars3BottomLeftIcon,
  ArrowRightStartOnRectangleIcon,
  ChatBubbleBottomCenterTextIcon,
  BookOpenIcon,
  AcademicCapIcon,
  NewspaperIcon,
  BellIcon,
  ArrowTrendingUpIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import {
  DocumentChartBarIcon as DocumentChartBarIconSolid,
  PresentationChartLineIcon as PresentationChartLineIconSolid,
  LightBulbIcon as LightBulbIconSolid,
  Bars3Icon as Bars3IconSolid,
  ArrowRightStartOnRectangleIcon as ArrowRightStartOnRectangleIconSolid,
  ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid,
  BookOpenIcon as BookOpenIconSolid,
  AcademicCapIcon as AcademicCapIconSolid,
  NewspaperIcon as NewspaperIconSolid,
  ArrowTrendingUpIcon as ArrowTrendingUpIconSolid,
} from '@heroicons/react/24/solid';
import { getScenarioList } from '../../../services/ApiActions/scenarioAct';
import { getKnowledgeBaseCategories } from '../../../services/ApiActions/knowledgeBaseAct';
import { getLastVideo } from '../../../services/ApiActions/lmsAct';
import { logout } from '../../../services/ApiActions/authAct';
import { ReactComponent as BestCallIcon } from '../../../best-call-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../profile-icon.svg';
import {
  getUserInitialLetter,
  getUserName,
} from '../../../helper/UtilityMethods';
import ResetPassword from '../../Profile/ResetPassword';
import ProfileModal from '../../Profile/ProfileModal';

import { useNotification } from '../../Context/NotificationContext';
import Logo from '../../Header/Logo';
import './sidebar.styles.scss';
const { Sider } = Layout;
const { Text } = Typography;

export type MenuListType = {
  name: string;
  title?: string;
  attributes?: { title: string; scenario_item: { id: string }[] };
  id: string;
  label?: string;
  updated_at: string;
  created_at: string;
  firstChildId?: string;
};
type MenuItem = Required<MenuProps>['items'][number];
interface SidebarProps {
  //onCollapse?: any;
}
const Sidebar: React.FC<SidebarProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [clickedMenu, setClickedMenu] = useState('');
  const [isResetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [headerMenuKey, setHeaderMenuKey] = useState('');
  const [footerMenuKey, setFooterMenuKey] = useState(1);
  const locationFragment = location.pathname.split('/');
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  const userString = sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : undefined;

  const { unreadCount } = useNotification();

  let selectedMenu = locationFragment[1];
  if (locationFragment.length > 2 && locationFragment[1] === 'scenarios') {
    selectedMenu = `menu-${locationFragment[1]}-${locationFragment[2]}`;
  }

  const defaultOpenkeys = location.pathname.includes('scenarios')
    ? ['scenarios']
    : [];
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [menuList, setMenuList] = useState<MenuItem[] | []>([]);
  const handleOnLogoutClick = () => {
    logout();
    navigate('/login');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showResetPasswordPage = () => {
    //setResetPasswordVisible(true);
    setHeaderMenuKey(Math.random().toString());
    setFooterMenuKey(Math.random());
    navigate('/reset-password');
  };

  const showAchievementsPage = () => {
    setHeaderMenuKey(Math.random().toString());
    setFooterMenuKey(Math.random());
    navigate('/achievements');
  };

  const handleNotification = () => {
    navigate('/user-notification');
  };
  const menuItems: MenuProps['items'] = [
    {
      key: 'user-name',
      title: '',
      label: (
        <div>
          <Text strong>{getUserName()}</Text>
          <br />
          {/* <Text type='secondary'>{user?.designation}</Text>
          <br /> */}
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'achievements',
      title: '',
      label: <div>Achievements</div>,
      onClick: showAchievementsPage,
    },
    {
      key: 'reset-password',
      title: '',
      label: <div>Reset Password</div>,
      onClick: showResetPasswordPage,
    },
    {
      key: 'logout',
      title: '',
      label: <div>Logout</div>,
      onClick: handleOnLogoutClick,
    },
  ];
  const renderChildren = (menuItem: any) => {
    if (menuItem.id == 'scenarios') {
      return (
        menuItem?.childMenu?.length &&
        menuItem.childMenu.map((childItem: MenuListType, j: number) => {
          const key = `menu-${menuItem.id}-${childItem.id}`;
          return {
            key: key,
            label: (
              <Link
                to={`/${menuItem.id}/${childItem.id}/${childItem.firstChildId}`}
              >
                {childItem.label}
              </Link>
            ),
          };
        })
      );
    } else if (menuItem.id == 'best-calls') {
      return (
        menuItem?.childMenu?.length &&
        menuItem.childMenu.map((childItem: MenuListType, j: number) => {
          let key = `menu-${menuItem.id}-${childItem.id}`;
          return {
            key: key,
            label: (
              <Link to={`/${menuItem.id}/${encodeURI(childItem.id)}`}>
                {childItem.label}
              </Link>
            ),
          };
        })
      );
    }
  };
  useEffect(() => {
    //, getAllCourses()
    Promise.all([getScenarioList(), getKnowledgeBaseCategories()])
      .then((response: any) => {
        //, departmentResponse, coursesResponse
        const [scenarioResponse, knowledgeBaseResponse] = response;
        const scenarioList = scenarioResponse.data.map(
          (item: MenuListType) => ({
            label: item.attributes?.title || '',
            id: `${item.id}`,
            firstChildId: item.attributes?.scenario_item[0]?.id || '',
          })
        );

        // const courseList = coursesResponse.data.map((item: any) => ({
        //     label: item?.attributes?.name || '',
        //     id: item.id,
        // }));
        /*{label: 'LMS', icon: AcademicCapIcon, id: 'lms'},
         */
        const items: any[] = [
          {
            label: 'Ask Me Anything',
            icon: ChatBubbleBottomCenterTextIcon,
            iconActive: ChatBubbleBottomCenterTextIconSolid,
            id: 'ask-me-anything',
          },
          {
            label: 'Scenarios',
            icon: BookOpenIcon,
            iconActive: BookOpenIconSolid,
            id: 'scenarios',
            childMenu: scenarioList,
          },
          {
            label: 'Knowledge Base',
            icon: LightBulbIcon,
            iconActive: LightBulbIconSolid,
            id: 'knowledge-base',
            firstChildId: encodeURI(
              knowledgeBaseResponse?.answer[0]?.category_slug || ''
            ),
          },
          {
            label: 'Best Calls',
            icon: NewspaperIcon,
            iconActive: NewspaperIconSolid,
            id: 'best-calls',
            childMenu: [],
          },
          {
            label: 'LMS',
            icon: AcademicCapIcon,
            iconActive: AcademicCapIconSolid,
            id: 'lms',
          },
          {
            label: 'Newsletter',
            icon: NewspaperIcon,
            iconActive: NewspaperIconSolid,
            id: 'newsletter',
            // defaultQueryParam: `?start-date=${dayjs()
            //   .startOf('month')
            //   .format('YYYY-MM-DD')}&end-date=${dayjs().format('YYYY-MM-DD')}`,
          },
          {
            label: 'Informed InvestoRR',
            icon: PresentationChartLineIcon,
            iconActive: PresentationChartLineIconSolid,
            id: 'informed-investor-rr',
            firstChildId: 'daily-trends',
            // defaultQueryParam:  `?start-date=${dayjs().startOf('month').format('YYYY-MM-DD')}&end-date=${dayjs().endOf('month').format('YYYY-MM-DD')}`
          },
          {
            label: 'Research',
            icon: DocumentChartBarIcon,
            iconActive: DocumentChartBarIconSolid,
            id: 'research-reports',
            firstChildId: 'research-parameters',
            // defaultQueryParam: `?start-date=${dayjs()
            //   .startOf('month')
            //   .format('YYYY-MM-DD')}&end-date=${dayjs()
            //   .endOf('month')
            //   .format('YYYY-MM-DD')}`,
          },
          {
            label: 'Whats Trending',
            icon: ArrowTrendingUpIcon,
            iconActive: ArrowTrendingUpIconSolid,
            id: 'whats-trending',
            firstChildId: 'founders-desk',
            // defaultQueryParam: `?start-date=${dayjs()
            //   .startOf('month')
            //   .format('YYYY-MM-DD')}&end-date=${dayjs()
            //   .endOf('month')
            //   .format('YYYY-MM-DD')}`,
          },
        ];
        setItems(items);
        const selectedMenuItem = selectedMenu.includes('scenarios')
          ? 'scenarios'
          : selectedMenu;
        setMenuList(mapItems(items, selectedMenuItem));
      })
      .catch((error) => {});
  }, []);
  const mapItems = (i: any, activeMenuKey: string) => {
    return i.map((menuItem: any) => {
      console.log(menuItem.id, '-', menuItem.iconActive);
      return {
        key: `${menuItem.id}`,
        icon:
          menuItem.id == 'best-calls' ? (
            <BestCallIcon className='custom-icon hero-icon-24' />
          ) : menuItem.id == activeMenuKey ? (
            <menuItem.iconActive className='custom-icon hero-icon-24' />
          ) : (
            <menuItem.icon className='custom-icon hero-icon-24' />
          ),
        label: menuItem?.childMenu?.length ? (
          `${menuItem.label}`
        ) : (
          <Link
            to={`${
              menuItem.firstChildId
                ? `/${menuItem.id}/${menuItem.firstChildId}${
                    menuItem.defaultQueryParam ? menuItem.defaultQueryParam : ''
                  }`
                : `/${menuItem.id}${
                    menuItem.defaultQueryParam ? menuItem.defaultQueryParam : ''
                  }`
            }`}
          >
            {menuItem.label}
          </Link>
        ),
        children: renderChildren(menuItem),
      };
    });
  };
  const handleMenuClick = (e: any) => {
    console.log(e);
    const newItems = { ...items };
    //const itemIndex = items.findIndex(x => x.id == e.key);

    setMenuList(mapItems(items, e.keyPath[e.keyPath.length - 1]));
    setFooterMenuKey(Math.random()); // to reset the selected menu
    //console.log(items[itemIndex].icon);
    //items[itemIndex].icon = item;
  };

  // const siderStyle: React.CSSProperties = {
  //   overflow: 'hidden',
  //   height: '100vh',
  //   position: 'fixed',
  //   insetInlineStart: 0,
  //   top: 0,
  //   bottom: 0,
  //   //scrollbarWidth: 'thin',
  //   scrollbarColor: 'unset',
  // };
  return (
    <Sider
      className='sidebar'
      trigger={null}
      width={285}
      collapsedWidth={82}
      collapsed={isCollapsed}
      //style={siderStyle}
    >
      <div className='logo'>
        <Flex justify='center' style={{ height: '64px' }}>
          {!isCollapsed && (
            <div style={{ flexGrow: 1 }}>
              <Logo width={150} />
            </div>
          )}
          {isCollapsed ? (
            <Bars3Icon
              className='custom-icon hero-icon-24'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsCollapsed((prevIsCollapsed) => {
                  ///console.log(!prevIsCollapsed);
                  //onCollapse(!prevIsCollapsed);
                  return !prevIsCollapsed;
                });
              }}
            />
          ) : (
            <Bars3BottomLeftIcon
              className='custom-icon hero-icon-24'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsCollapsed((prevIsCollapsed) => {
                  ///console.log(!prevIsCollapsed);
                  //onCollapse(!prevIsCollapsed);
                  return !prevIsCollapsed;
                });
              }}
            />
          )}
        </Flex>
      </div>

      <Menu
        key={headerMenuKey}
        onClick={(e) => handleMenuClick(e)}
        //212px when notification and lms uncommented
        style={{ height: 'calc(100vh - 212px)', overflowY: 'auto' }}
        className='custom-menu'
        mode='inline'
        defaultSelectedKeys={[selectedMenu]}
        defaultOpenKeys={defaultOpenkeys}
        items={menuList}
      />
      <Menu
        key={footerMenuKey}
        onClick={(e) => {
          setMenuList(mapItems(items, ''));
          setHeaderMenuKey(Math.random().toString());
        }}
        className='custom-menu footer-menu'
        mode='vertical'
        style={{ background: 'transparent' }}
        defaultSelectedKeys={[selectedMenu]}
      >
        <Menu.Item
          key='user-notification'
          style={{ paddingLeft: '24px' }}
          icon={
            <Badge dot={unreadCount > 0} offset={[-6, 10]} className='pointer'>
              <BellIcon className='custom-icon hero-icon-24' />
            </Badge>
          }
          onClick={handleNotification}
        >
          Notification
        </Menu.Item>
      </Menu>
      <Dropdown menu={{ items: menuItems }} placement='bottom'>
        <Button size='large' className='profile-btn' style={{ width: '100%' }}>
          <ProfileIcon />
          {!isCollapsed && getUserName()}
        </Button>
      </Dropdown>
    </Sider>
  );
};
export default Sidebar;
