import React, { useEffect, useContext, useState, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Layout,
  Row,
  Col,
  Progress,
  Flex,
  Input,
  GetRef,
  Card,
  Typography,
  Empty,
  AutoComplete,
} from 'antd';
import ReactPlayer from 'react-player';
import BreadcrumbContext from '../../components/Context';
import Loader from '../../components/Layout/Loader';
import {
  updateElapsedTime,
  getCallsByDepartment,
  getCallById,
  searchCalls,
} from '../../services/ApiActions/bestCallAct';
import AudioSummary from './AudioPlayer';
import { ReactComponent as BestCallIcon } from '../../best-call-icon.svg';
import PageHeading from '../../components/Layout/PageHeading';
import AudioCard from './AudioCard';

type InputRef = GetRef<typeof Input>;
interface DataType {
  key: string;
  play_btn: React.ReactElement;
  name: string;
  last_modified: string;
  play_time: string;
  recordName: string;
}
interface DataType {
  key: string;
  play_btn: React.ReactElement;
  name: string;
  last_modified: string;
  play_time: string;
  recordName: string;
  audioLink: string;
}
type DataIndex = keyof DataType;

const { Content, Sider } = Layout;
const { Search } = Input;
interface RecordingNameProps {
  recordKey: string;
  recordName: string;
  audioURL: string;
}

const siderStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  paddingLeft: '10px',
};
const BestCallDetailsPage = () => {
  const cancelSource = axios.CancelToken.source();
  const navigate = useNavigate();
  const [selectedAudio, setSelectedAudio] = useState({
    audio_link: '',
    audio_id: '',
    name: '',
    is_completed: false,
    selectedSection: '',
  });
  const [summaries, setSummaries] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  //const [dct, setDct] = useState('00:00');
  const [playing, setPlaying] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playedDuration, setPlayedDuration] = useState(0);
  const [loadedDuration, setLoadedDuration] = useState(0);
  const [calls, setCalls] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [playOnRender, setPlayOnRender] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const { departmentName } = useParams();
  const userString = sessionStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : undefined;
  const player = useRef<ReactPlayer>(null);
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  useEffect(() => {
    setIsLoading(true);
    getCallsByDepartment({
      department_name: departmentName,
      user_email: user.email,
    })
      .then((response: any) => {
        const { data, status } = response;
        const callList = data.map((i: any) => {
          return {
            key: i.id,
            play_time: i.time_stamp,
            duration: i.duration,
            audioLink: i.audio_link,
            name: i.name,
            is_completed: i.is_completed,
          };
        });
        setCalls(callList);
        setFilteredCalls(callList);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [departmentName]);
  useEffect(() => {
    const { audio_id: recordId, name, is_completed } = selectedAudio;
    if (playedDuration > 0 && elapsedTime % 30000 == 0 && !is_completed) {
      //update only when isComplete is false;
      handleOnProgress({
        audio_id: recordId,
        time_stamp: playedDuration,
        name: name,
      });
    }
  }, [elapsedTime]);
  const searchBestCalls = async (
    searchTerm: string,
    departmentName: string
  ) => {
    try {
      const term = searchTerm && searchTerm.trim();
      if (!term) {
        setSelectedAudio({
          audio_link: '',
          audio_id: '',
          name: '',
          is_completed: false,
          selectedSection: '',
        });
        setFilteredCalls(calls);
        return;
      }
      const result: any = await searchCalls(
        { searchTerm: term, departmentName },
        cancelSource.token
      );
      const callList = result.data.map((i: any) => {
        return {
          key: i.id,
          play_time: i.time_stamp,
          duration: i.duration,
          audioLink: i.audio_link,
          name: i.name,
          is_completed: i.is_completed,
        };
      });
      setFilteredCalls(callList);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  /*
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (assesmentId && courseId) {
          const response: any = await getAssesmentQuestions(assesmentId, courseId);
          setAssesmentDetails(response.data);
          setCourseName(sessionStorage.getItem('course_name') || '');
        }
      } catch (error) {
        //setErrorMessage(error.error?.message);//Fix me giving type error
        setErrorMessage('Unable to get assessment');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [assesmentId]);
  */
  useEffect(() => {
    if (!departmentName) {
      return;
    }

    searchBestCalls(searchQuery, departmentName);
    return () => {
      cancelSource.cancel('Component unmounted or dependency changed');
    };
  }, [searchQuery, departmentName]);
  const handleOnSearch = (e: any) => {
    const query = e;
    setSearchQuery(query);
    // if (query === '') {
    //   setFilteredCalls(calls);
    // } else {
    //   const filteredItems = calls.filter((call: any) => {
    //     const name = call.name;
    //     return name.toString().toLowerCase().indexOf(query.toLowerCase()) > -1;
    //   });
    //   setFilteredCalls(filteredItems);
    // }
  };
  const handleOnSearchChanges = (e: any) => {
    const query = e.target.value;
    handleOnSearch(query);
  };
  const handleOnSelect = (e: any) => {
    const filteredCall = filteredCalls.find((call: any) => call.name === e);
    console.log(filteredCall);
    handleOnAudioClick(filteredCall, 'playing');
    //const query = e.target.value;
    //handleOnSearch(query);
  };
  const handlePlayPause = () => {
    setPlaying(!playing);
  };
  const goToBestCall = () => {
    navigate('/best-calls');
  };
  const handleMuteUnmute = () => {
    console.log(!isMute);
    setIsMute(!isMute);
  };
  const handleOnProgress = ({ time_stamp, audio_id, is_completed }: any) => {
    const data = {
      time_stamp: time_stamp,
      audio_id: audio_id,
      user_email: user.email,
      ...(is_completed && { is_completed: is_completed }),
    };
    updateElapsedTime(data)
      .then((response: any) => {
        //console.log(response);
      })
      .catch((error) => {
        //console.log(error)
      });
  };

  const handleOnAudioClick = (record: any, sectionName: string) => {
    const { audio_id: recordId } = selectedAudio;
    if (record.key === recordId) {
      return;
    }
    setPlaying(false);
    getCallById({ audio_id: record.key, user_email: user.email }).then(
      (response: any) => {
        const { data: audioRecord, status } = response;
        setSelectedAudio({ ...audioRecord, selectedSection: sectionName });
        setSummaries(audioRecord.summary || []);
        const timer = setTimeout(() => {
          console.log('stamp-', audioRecord.time_stamp);

          if (player.current) {
            if (audioRecord.time_stamp !== player.current.getDuration()) {
              setPlayOnRender(true);
            } else {
              setPlayOnRender(false);
            }
            if (audioRecord.time_stamp > 0) {
              player.current.seekTo(Number(audioRecord.time_stamp), 'seconds');
            }
          } else {
            //setPlaying(true)
          }
        }, 1000);
        return () => clearTimeout(timer);
        //
      }
    );
  };
  const renderAudioCard = (cards: any, sectionName: string) => {
    const { selectedSection } = selectedAudio;
    return cards.map((call: any) => {
      const playedPercent: any = call.duration
        ? (call.play_time / call.duration) * 100
        : 0;
      return (
        <Col
          style={{ width: '310px' }}
          key={`${sectionName}-${call.id}`}
          onClick={() => handleOnAudioClick(call, sectionName)}
        >
          <AudioCard
            callName={call.name}
            callDuration={call.duration}
            callPlayedDuration={call.play_time}
            isSelected={call.key == recordId && selectedSection == sectionName}
            playedPercent={playedPercent}
          />
        </Col>
      );
    });
  };

  const location = useLocation();
  useEffect(() => {
    addBreadcrumbitem({
      breadCrumb: [
        { label: 'Spoon Feeding', url: '' },
        { label: 'Best Calls', url: location.pathname },
        { label: departmentName, url: location.pathname },
      ],
      headingInfo: { title: `${departmentName}`, description: '' },
    });
  }, []);

  const { audio_id: recordId, name } = selectedAudio;
  const playedPercent: any = duration ? (playedDuration / duration) * 100 : 0;
  const loadedPercent: any = duration ? (loadedDuration / duration) * 100 : 0;
  const continuePlaying = filteredCalls.filter(
    (call: any) => call.play_time > 0 && !call.is_completed
  ); //and not isComplete
  if (isLoading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }
  return (
    <div className='main-content'>
      <PageHeading
        heading={departmentName || ''}
        onBackClick={goToBestCall}
        rightContent={
          <AutoComplete
            options={filteredCalls.map((call: any) => {
              return { label: call.name, value: call.name };
            })}
            onSelect={handleOnSelect}
            //onChange={handleOnSearchChanges}
            onSearch={handleOnSearch}
          >
            <Search
              placeholder='Search'
              allowClear
              //onSearch={handleOnSearch}
              //onChange={handleOnSearchChanges}
              style={{ width: 200 }}
            />
          </AutoComplete>
        }
      />
      {recordId && (
        <Flex style={{ marginBottom: '20px', maxWidth: '1200px' }} gap={24}>
          <Card
            style={{
              width: '400px',
              background:
                'linear-gradient(111.74deg, rgba(174, 160, 255, 0.3) 0%, rgba(154, 84, 224, 0.3) 100%)',
            }}
          >
            <Typography.Title
              className='page-sub-heading'
              level={4}
              style={{ margin: 0 }}
            >
              Now Playing
            </Typography.Title>
            <Flex>
              <BestCallIcon className='custom-icon hero-icon-12' />
              <div
                style={{ marginBottom: '10px', marginLeft: '5px' }}
                className='ellipsis-text'
              >
                {name}
              </div>
            </Flex>
            <ReactPlayer
              loop={false}
              progressInterval={1000}
              volume={isMute ? 0 : 1}
              ref={player}
              width='100%'
              height='40px'
              controls={true}
              playing={playing}
              url={selectedAudio && selectedAudio.audio_link}
              onDuration={(sec) => {
                setDuration(sec);
              }}
              onPause={() => {
                setPlaying(false);
                //handleOnProgress({audio_id: recordId, time_stamp: duration});
              }}
              onEnded={() => {
                setPlaying(false);
                handleOnProgress({
                  audio_id: recordId,
                  time_stamp: duration,
                  is_completed: true,
                });
              }}
              onSeek={(e) => {
                if (playOnRender) {
                  if (player.current && e < player.current.getDuration()) {
                    setPlaying(true);
                  }
                  setPlayOnRender(false);
                }
              }}
              onProgress={(e) => {
                setPlayedDuration(e.playedSeconds);
                setLoadedDuration(e.loadedSeconds);
                setElapsedTime((curr) => curr + 1000);
              }}
              className='best-call-player'
              style={{ maxWidth: '100%', height: 'auto', marginBottom: 0 }}
            />
            {/*<div className="bottom-panel" style={{marginBottom: "15px", padding: "5px 10px", backgroundColor:"#eee", borderRadius: "20px"}}>
                              <Flex gap="small">
                                  <span onClick={handlePlayPause}>{playing ? <PauseCircleOutlined style={{fontSize: '24px'}}/> : <PlayCircleOutlined style={{fontSize: '24px'}}/>}</span>
                                  <span style={{fontSize: 'small', display: 'inline-block', whiteSpace: 'nowrap', lineHeight: '1.8'}}>{formatDuration(playedDuration)} / {formatDuration(duration)}</span>
                                  <Progress strokeColor="#ccc" showInfo={false} percent={loadedPercent} success={{ percent: playedPercent, strokeColor: "#333" }} />
                                  <span onClick={handleMuteUnmute}>{isMute ? <MutedOutlined  style={{fontSize: '24px'}}/> : <SoundOutlined style={{fontSize: '24px'}}/>}</span>
                              </Flex>
                        </div>*/}
          </Card>

          <AudioSummary summaries={summaries} />
        </Flex>
      )}
      {continuePlaying.length <= 0 ? (
        <></>
      ) : (
        <section>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Continue Listening
          </Typography.Title>
          <Row gutter={[15, 15]}>
            {renderAudioCard(continuePlaying, 'continue-playing')}
          </Row>
        </section>
      )}
      <section>
        <Typography.Title
          className='page-sub-heading'
          level={5}
          style={{ margin: 0 }}
        >
          Playlist
        </Typography.Title>

        {filteredCalls.length <= 0 ? (
          <Empty
            style={{ marginTop: '5em' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Typography.Text>
                {calls.length <= 0
                  ? 'No items found.'
                  : 'No items found. Try some different  search term.'}
              </Typography.Text>
            }
          />
        ) : (
          <Row gutter={[15, 15]}>
            {renderAudioCard(filteredCalls, 'playing')}
          </Row>
        )}
      </section>
    </div>
  );
};

export default BestCallDetailsPage;
