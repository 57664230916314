import React, { useEffect, useState } from 'react';
import {
  Layout,
  Input,
  Radio,
  Form,
  Button,
  Typography,
  Breadcrumb as AntBreadcrumb,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  saveAssesmentResponse,
  getAssesmentQuestions,
} from '../services/ApiActions/lmsAct';
import LMSLayout from './LMSLayout';
import Loader from '../components/Layout/Loader';
import PageHeading from '../components/Layout/PageHeading';
import { Content } from 'antd/es/layout/layout';

const { TextArea } = Input;
const { Text } = Typography;

const AssesmentPage: React.FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [courseName, setCourseName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [assesmentDetails, setAssesmentDetails] = useState<any>(null);
  const { courseId, assesmentId } = useParams<{
    courseId: string;
    assesmentId: string;
  }>();
  const navigate = useNavigate();

  const getOptionClassname = (
    is_correct: boolean,
    optionIndex: number,
    submitted_option: number = 0
  ) => {
    if (
      typeof is_correct === 'undefined' ||
      optionIndex !== submitted_option - 1
    )
      return null;
    return is_correct
      ? { className: 'correct-answer', text: '(Correct Answer)' }
      : { className: 'incorrect-answer', text: '(Wrong Answer)' };
  };

  const onFinish = (values: any) => {
    const formValues = Object.entries(values).map(([key, value]) => ({
      submitted_answer: value + '',
      question_id: Number(key.replace('question-', '')),
    }));
    handleOnSubmitAssesment({
      responses: formValues,
      assessment_id: assesmentDetails.id,
      course_id: Number(courseId),
    });
  };

  const handleOnSubmitAssesment = async (data: any) => {
    try {
      setIsProcessing(true);
      const response: any = await saveAssesmentResponse(data);
      const assessmentResponse = response.data;
      setAssesmentDetails({ ...assessmentResponse, is_submitted: true });
    } catch (error) {
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (assesmentId && courseId) {
          const response: any = await getAssesmentQuestions(
            assesmentId,
            courseId
          );
          setAssesmentDetails(response.data);
          setCourseName(sessionStorage.getItem('course_name') || '');
        }
      } catch (error) {
        //setErrorMessage(error.error?.message);//Fix me giving type error
        setErrorMessage('Unable to get assessment');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [assesmentId]);

  if (isLoading) {
    return <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />;
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <Layout>
      <Layout className='max-width'>
        <Content>
          <PageHeading
            onBackClick={() => navigate(`/lms/course/${courseId}`)}
            heading={assesmentDetails.name}
            rightContent={
              assesmentDetails.assessment_result !== undefined ? (
                <Text
                  style={{
                    borderRadius: '4px',
                    padding: '5px 25px',
                    color: '#fff',
                    backgroundColor:
                      assesmentDetails.assessment_result === 'pass'
                        ? '#52C41A'
                        : '#FF4D4F',
                  }}
                  type='success'
                >
                  {assesmentDetails.assessment_result === 'pass'
                    ? 'Pass'
                    : 'Fail'}
                </Text>
              ) : (
                <></>
              )
            }
          />
          <AntBreadcrumb style={{ margin: '0 0 16px' }}>
            <AntBreadcrumb.Item>
              <a href='/lms'>LMS</a>
            </AntBreadcrumb.Item>
            <AntBreadcrumb.Item>
              <a href={`/lms/course/${courseId}`}>{courseName}</a>
            </AntBreadcrumb.Item>
            <AntBreadcrumb.Item>{assesmentDetails.name}</AntBreadcrumb.Item>
          </AntBreadcrumb>
          <div
            style={
              {
                //padding: '0 10px 0',
                //maxHeight: 'calc(100vh - 150px)',
                //width: '100%',
                //overflow: 'auto',
              }
            }
          >
            <Form form={form} onFinish={onFinish} className='quiz-question'>
              {assesmentDetails?.assessment_questions.map(
                (question: any, index: number) => {
                  const {
                    is_correct,
                    correct_answer,
                    options,
                    submitted_answer,
                  } = question;
                  const answerIndex = correct_answer - 1;
                  return (
                    <div key={question.id}>
                      <p style={{ marginTop: 0 }}>
                        {index + 1}. {question.question}
                      </p>
                      <Form.Item
                        style={{ marginBottom: '20px' }}
                        name={`question-${question.id}`}
                        rules={[
                          { required: true, message: 'Select your answer' },
                        ]}
                      >
                        {question.is_multiple_choice_question ? (
                          <Radio.Group
                            //size="large"
                            defaultValue={Number(question.submitted_answer)}
                            disabled={assesmentDetails.is_attempted}
                          >
                            {options.map((option: any, idx: number) => {
                              const answer = getOptionClassname(
                                is_correct,
                                idx,
                                submitted_answer
                              );
                              return (
                                <div
                                  style={{
                                    marginBottom: '10px',
                                  }}
                                  key={idx}
                                >
                                  <Radio
                                    className={answer?.className}
                                    value={idx + 1}
                                  >
                                    {option.option} {answer?.text}
                                  </Radio>
                                  <br />
                                </div>
                              );
                            })}
                          </Radio.Group>
                        ) : (
                          <TextArea
                            //size="large"
                            autoSize={{ minRows: 5 }}
                            readOnly={assesmentDetails.is_attempted}
                            placeholder='Your Answer'
                            defaultValue={question.submitted_answer}
                          />
                        )}
                      </Form.Item>
                      {typeof is_correct !== 'undefined' &&
                        question.is_multiple_choice_question && (
                          <div>
                            {is_correct ? (
                              <Text type='success'>
                                <CheckOutlined /> Correct!
                              </Text>
                            ) : (
                              <Text type='danger'>
                                <CloseOutlined /> Wrong!
                              </Text>
                            )}
                          </div>
                        )}
                      {typeof is_correct !== 'undefined' &&
                        !is_correct &&
                        !isProcessing &&
                        answerIndex >= 0 && (
                          <div>
                            *Correct answer is{' '}
                            <span>"{options[answerIndex].option}"</span>.
                          </div>
                        )}
                    </div>
                  );
                }
              )}
              {assesmentDetails.is_submitted ? (
                <div>
                  <Form.Item style={{ marginBottom: '5px' }}>
                    <Button
                      style={{ background: '#389E0D', color: '#fff' }}
                      icon={<CheckCircleOutlined />}
                      disabled
                    >
                      Successfully Submitted
                    </Button>
                  </Form.Item>
                  <Text italic>
                    Thank you for your response! Your answers have been
                    recorded. Please check back later for updates.
                  </Text>
                </div>
              ) : (
                !assesmentDetails.is_attempted && (
                  <div>
                    <Form.Item>
                      <Button
                        type='primary'
                        loading={isProcessing}
                        htmlType='submit'
                        disabled={assesmentDetails.is_attempted}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                )
              )}
            </Form>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AssesmentPage;
